import React, { useState, useEffect } from "react"
import { Container } from "@material-ui/core"
import UserPanel from "../UserPanel"
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import Title from "../Title"
import axios from "../../../axiosConfig"
import { errorNotifier, successNotifier } from "../../Notifier"
import IconButton from "@material-ui/core/IconButton"
import CardGiftcard from "@material-ui/icons/CardGiftcard"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import DoneOutline from "@material-ui/icons/DoneOutline"
import MyDialog from "../../MyDialog"

const useStyles = makeStyles(() => ({
  rowHeader: {
    fontWeight: "bold",
  },
}))

const GlobalCards = ({ user, history, setLoading }) => {
  const classes = useStyles()
  const [globalList, setGlobalList] = useState([])
  const [dialogStatus, setDialogStatus] = useState(false)
  const [codeID, setCodeID] = useState("")

  const handleNo = () => {
    setDialogStatus(false)
  }
  const handleYes = () => {
    setDialogStatus(false)
    changeCardStatus()
  }

  const changeCardStatus = () => {
    setDialogStatus(false)
    setCodeID("")
    axios
      .put(`codes`, {
        codeID: codeID,
      })
      .then((res) => {
        successNotifier(`Card status changed to YES succesfully`)
        setLoading(false)
        getCards()
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, please try again ...(${error.message})`
        )
        setLoading(false)
        getCards()
      })
  }

  const getCards = () => {
    setLoading(true)
    axios
      .post(`records/myglobal`)
      .then((res) => {
        if (res.data.length === 0) {
          successNotifier("No uncharged cards found")
        }
        setGlobalList(res.data)
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, cannot get global list ...(${error.message})`
        )
        setLoading(false)
      })
  }

  useEffect(() => {
    getCards()
    // eslint-disable-next-line
  }, [])

  return (
    <UserPanel>
      <Container maxWidth={false}>
        <MyDialog
          dialogStatus={dialogStatus}
          handleYes={handleYes}
          handleNo={handleNo}
        ></MyDialog>
        <Grid container spacing={3}>
          <>
            <Grid item xs={12} container justify="space-evenly">
              <Grid item xs={12}>
                <Title>Global Cards Information</Title>
              </Grid>
            </Grid>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.rowHeader}>Name</TableCell>
                  <TableCell className={classes.rowHeader}>Type</TableCell>

                  <TableCell className={classes.rowHeader}>Period</TableCell>
                  <TableCell className={classes.rowHeader}>Code</TableCell>
                  <TableCell className={classes.rowHeader} align="center">
                    Staus
                  </TableCell>
                  <TableCell className={classes.rowHeader} align="center">
                    Use
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {globalList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.global}</TableCell>
                    <TableCell>{row.gtype}</TableCell>
                    <TableCell>{row.period}</TableCell>
                    <TableCell>{row.code}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="primary"
                        aria-label="use"
                        component="span"
                        onClick={() => {
                          setDialogStatus(true)
                          setCodeID(row.codeID)
                        }}
                      >
                        <DoneOutline></DoneOutline>
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        disabled={row.total < 1}
                        color="primary"
                        aria-label="use"
                        component="span"
                        onClick={() => {
                          history.push({
                            pathname: `/user_panel/mycards/global/use`,
                            state: row,
                          })
                        }}
                      >
                        <CardGiftcard></CardGiftcard>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        </Grid>
      </Container>
    </UserPanel>
  )
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default withRouter(connect(mapStateToProps)(GlobalCards))
