import React from "react"
import { Typography } from "@material-ui/core"

import NotFoundImg from "./../assets/404.png"

function NotFoundContent(props) {
  let defaultText = "Sorry page is not available"
  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 60px)",
        textAlign: "center",
        paddingTop: "100px",
      }}
    >
      <img
        alt="not found"
        src={NotFoundImg}
        style={{ width: "400px", height: "300px" }}
      ></img>
      <Typography variant="h5">
        {props.message ? props.message : defaultText}
      </Typography>
    </div>
  )
}

export default NotFoundContent
