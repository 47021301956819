import React, { useState, useEffect } from "react"
import { Container } from "@material-ui/core"
import UserPanel from "../UserPanel"
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import Title from "../Title"
import axios from "../../../axiosConfig"
import { errorNotifier, successNotifier } from "../../Notifier"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import IconButton from "@material-ui/core/IconButton"
import DoneOutline from "@material-ui/icons/DoneOutline"
import HelpOutline from "@material-ui/icons/HelpOutline"
import MyDialog from "../../MyDialog"
import HelperDialog from "../../HelperDialog"

const useStyles = makeStyles(() => ({
  rowHeader: {
    fontWeight: "span",
  },
}))

const LocalCards = ({ user, history, setLoading }) => {
  const classes = useStyles()
  const [localList, setLocalList] = useState([])
  const [dialogStatus, setDialogStatus] = useState(false)
  const [helperDialogStatus, setHelperDialogStatus] = useState(false)
  const [codeID, setCodeID] = useState("")

  const [title, setTitle] = useState("")
  const [helper, setHelper] = useState("")

  const handleNo = () => {
    setDialogStatus(false)
  }
  const handleYes = () => {
    setDialogStatus(false)
    changeCardStatus()
  }

  const changeCardStatus = () => {
    setDialogStatus(false)
    setCodeID("")
    axios
      .put(`codes`, {
        codeID: codeID,
      })
      .then((res) => {
        successNotifier(`Card status changed to YES succesfully`)
        setLoading(false)
        getCards()
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, please try again ...(${error.message})`
        )
        setLoading(false)
        getCards()
      })
  }

  const getCards = () => {
    setLoading(true)
    axios
      .post(`records/mylocal`)
      .then((res) => {
        if (res.data.length === 0) {
          successNotifier("No uncharged cards found")
        }
        setLocalList(res.data)
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, cannot get local list ...(${error.message})`
        )
        setLoading(false)
      })
  }

  useEffect(() => {
    getCards()
    // eslint-disable-next-line
  }, [])

  return (
    <UserPanel>
      <Container maxWidth={false}>
        <MyDialog
          dialogStatus={dialogStatus}
          handleYes={handleYes}
          handleNo={handleNo}
        ></MyDialog>
        <HelperDialog
          dialogStatus={helperDialogStatus}
          title={title}
          handleClose={() => {
            setHelperDialogStatus(false)
          }}
        >
          {helper}
        </HelperDialog>
        <Grid container spacing={3}>
          <>
            <Grid item xs={12} container justify="space-evenly">
              <Grid item xs={12}>
                <Title>Local Cards Information</Title>
              </Grid>
            </Grid>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.rowHeader}>Name</TableCell>
                  <TableCell className={classes.rowHeader}>Period</TableCell>
                  <TableCell className={classes.rowHeader}>User</TableCell>
                  <TableCell className={classes.rowHeader}>Code</TableCell>
                  <TableCell className={classes.rowHeader} align="center">
                    Staus
                  </TableCell>
                  <TableCell className={classes.rowHeader} align="center">
                    Help
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.local}</TableCell>
                    <TableCell>{row.period}</TableCell>
                    <TableCell>
                      {row.local.toLocaleLowerCase() === "hdbox"
                        ? row.code.split("_")[0]
                        : "..."}
                    </TableCell>
                    <TableCell>
                      {row.local.toLocaleLowerCase() === "hdbox"
                        ? row.code.split("_")[1]
                        : row.code}
                    </TableCell>

                    <TableCell align="center">
                      <IconButton
                        color="primary"
                        aria-label="use"
                        component="span"
                        onClick={() => {
                          setDialogStatus(true)
                          setCodeID(row.codeID)
                        }}
                      >
                        <DoneOutline></DoneOutline>
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      {(row.local.toLocaleLowerCase() === "mixmedia" ||
                        row.local.toLocaleLowerCase() === "hdbox") && (
                        <IconButton
                          color="primary"
                          aria-label="use"
                          component="span"
                          onClick={() => {
                            setHelperDialogStatus(true)
                            if (row.local.toLocaleLowerCase() === "mixmedia") {
                              setTitle("Mixmedia Activation Guide")
                              setHelper(HelperMixmedia)
                            }
                            if (row.local.toLocaleLowerCase() === "hdbox") {
                              setTitle("HDBOX Activation Guide")
                              setHelper(HelperHDBOX)
                            }
                          }}
                        >
                          <HelpOutline></HelpOutline>
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        </Grid>
      </Container>
    </UserPanel>
  )
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default withRouter(connect(mapStateToProps)(LocalCards))

const HelperMixmedia = (
  <>
    <h4>Method One - Activation via SMS</h4>
    <br></br>
    <h5>
      <span style={{ color: "darkgreen" }}>Step 1:</span> Send SMS message for
      one of the following phone number 07502504444 , 07722504444, 07722604444,
      and type the following text.
    </h5>
    <h5>
      <span style={{ color: "darkgreen" }}>Step 2:</span> Press star * then type
      your receiver serial number ( CASE KEY )
    </h5>
    <h5>
      <span style={{ color: "darkgreen" }}>Step 3:</span> Press star * then type
      the following Code number , then press hash #
    </h5>

    <h5>your SMS message format must be follow this rule</h5>
    <h5>* CASE KEY * Code Number#</h5>
    <br></br>
    <br></br>

    <h4>Method Two - Activation via SMS</h4>
    <br></br>
    <h5>
      <span style={{ color: "darkgreen" }}>Step 1:</span> Install MixMedia
      mobile application on your smart phone
    </h5>
    <h5>
      <span style={{ color: "darkgreen" }}>Step 2:</span> choose activation
    </h5>
    <h5>
      <span style={{ color: "darkgreen" }}>Step 3:</span> Key in your CASE KEY
    </h5>
    <h5>
      <span style={{ color: "darkgreen" }}>Step 4:</span> Key in Code Number
    </h5>
    <h5>
      <span style={{ color: "darkgreen" }}>Step 5:</span> Press activate
    </h5>
  </>
)

const HelperHDBOX = (
  <>
    <h4>Activation via SMS</h4>
    <br></br>
    <h5>For activate your subscription please follow these steps</h5>
    <h5>
      <span style={{ color: "darkgreen" }}>Step 1:</span> Send a message to
      07506436363, 07734491000 and message should be <br></br> 2 * SN * User
      Number * Password
    </h5>
    <br></br>
    <h5>
      <span style={{ color: "darkgreen" }}>Step 2:</span> your device will be
      update in one minute,
      <br></br> for knowing your expiration time please send <br></br> 3 * SN
      <br></br> for updating your device please send <br></br> 4 * SN
    </h5>
  </>
)
