import React from "react"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"

import "date-fns"

export default function MaterialUIPickers(props) {
  const { value, fn, label, style } = props

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        style={style}
        autoOk
        animateYearScrolling
        orientation="landscape"
        variant="inline"
        format="yyyy/MM/dd"
        margin="none"
        id={`${label}`}
        label={`${label}`}
        value={value}
        onChange={fn}
        inputVariant="outlined"
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

// edit arabic localization
// Path = /node_modules/date-fns/esm/locale/ar/_lib/localize/index.js
// var weekdayValues = {
//   narrow: ['١ شەمە', '٢ شەمە', '٣ شەمە', '٤ شەمە', '٥ شەمە', 'هەینی', 'شەمە'],
//   short: ['١ شەمە', '٢ شەمە', '٣ شەمە', '٤ شەمە', '٥ شەمە', 'هەینی', 'شەمە'],
//   long: ['١ شەمە', '٢ شەمە', '٣ شەمە', '٤ شەمە', '٥ شەمە', 'هەینی', 'شەمە']
// };
// var monthValues = {
//   short:['کانونی دوەم', 'شوبات', 'ئادار', 'نیسان', 'ئایار', 'حوزەیران', 'تەموز', 'ئاب', 'ئەیلول', 'تشرینی یەکەم', 'تشرینی دوەم', 'تشرینی یەکەم'],
//   long: ['کانونی دوەم', 'شوبات', 'ئادار', 'نیسان', 'ئایار', 'حوزەیران', 'تەموز', 'ئاب', 'ئەیلول', 'تشرینی یەکەم', 'تشرینی دوەم', 'تشرینی یەکەم']
// };
