// import all actionas actionTypes  -single source of truth
import * as actionTypes from "../actions/actionTypes"

const drawer = (
  state = {
    open: true,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SET_DRAWER: //  adding information
      return { ...state, ...action.payload }
    default:
      // default state of the reducer
      return state
  }
}

export default drawer
