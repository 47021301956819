import React, { useState } from "react"
import { Container } from "@material-ui/core"
import UserPanel from "./../UserPanel"
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Box,
  Button,
} from "@material-ui/core"
import { withRouter } from "react-router-dom"
import axios from "../../../axiosConfig"
import { errorNotifier, successNotifier } from "../../Notifier"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Title from "../Title"

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 355,
  },
}))

const intialValues = {
  code: "",
  serialNo: "",
}

const Search = ({ setLoading, history }) => {
  const classes = useStyles()
  const [values, setValues] = useState(intialValues)
  const [logs, setLogs] = useState([])

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const searchLog = () => {
    if (values.code === "" && values.serialNo === "") {
      errorNotifier("Please at least input code number or serial number")
      return
    }

    let data = {
      card: values.code !== "" ? values.code : null,
      sn: values.serialNo !== "" ? values.serialNo : null,
    }

    setLoading(true)
    axios
      .post(`Logs/search`, data)
      .then((res) => {
        if (res.data.length === 0) {
          successNotifier(
            `No log found, please try another card or serial number`
          )
        } else {
          setLogs(res.data)
        }
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, cannot get search result ...(${error.message})`
        )
        setLoading(false)
      })
  }

  return (
    <UserPanel>
      <Container maxWidth={false}>
        <Card>
          <CardHeader
            title="Search"
            subheader="Search for logs of single card or device serial number"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Card"
                  name="code"
                  onChange={handleChange}
                  value={values.code}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Serial No"
                  name="serialNo"
                  onChange={handleChange}
                  value={values.serialNo}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button color="primary" variant="contained" onClick={searchLog}>
              Search
            </Button>
          </Box>
        </Card>
        {logs.length > 0 && (
          <Grid container spacing={3} style={{ marginTop: "5px" }}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <>
                  <Grid item xs={12} container justify="space-evenly">
                    <Grid item xs={12}>
                      <Title>Card Logs</Title>
                    </Grid>
                  </Grid>

                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.rowHeader} align="left">
                          Date
                        </TableCell>
                        <TableCell className={classes.rowHeader} align="left">
                          Card
                        </TableCell>
                        <TableCell className={classes.rowHeader} align="left">
                          SerialNo
                        </TableCell>
                        <TableCell className={classes.rowHeader} align="left">
                          Submit
                        </TableCell>
                        <TableCell className={classes.rowHeader} align="center">
                          Result
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {logs.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">
                            {new Date(`${row.date}Z`)
                              .toString()
                              .replace("GMT+0300 (Arabian Standard Time)", "")}
                          </TableCell>
                          <TableCell align="left">{row.card}</TableCell>
                          <TableCell align="left">{row.sn}</TableCell>
                          <TableCell align="left">{row.submit}</TableCell>
                          <TableCell align="center">{row.result}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Container>
    </UserPanel>
  )
}

export default withRouter(Search)
