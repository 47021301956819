/*eslint-disable*/
import React from "react"

// react components for routing our app without refresh
import { Link } from "react-router-dom"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Tooltip from "@material-ui/core/Tooltip"
import Badge from "@material-ui/core/Badge"

// @material-ui/icons
import { Apps } from "@material-ui/icons"

import styles from "./headerLinksStyle"

const useStyles = makeStyles(styles)

function HeaderLinks(props) {
  const classes = useStyles()

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link
          to="/global/gshare"
          color="transparent"
          className={classes.navLink}
        >
          <span>GSHArE</span>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          to="/global/forever"
          color="transparent"
          className={classes.navLink}
        >
          <span>FOREVER</span>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          to="/global/funcam"
          color="transparent"
          className={classes.navLink}
        >
          <span>FUNCAM</span>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/local" color="transparent" className={classes.navLink}>
          <span>Extra Packages</span>
        </Link>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Link to="/user_panel" color="transparent" className={classes.navLink}>
          <i className={classes.socialIcons + " fas fa-user-circle"} />
        </Link>
      </ListItem>
    </List>
  )
}

export default HeaderLinks
