import React from "react"

const Logo = (props) => {
  const { width, height } = props
  return (
    <img
      alt="Logo"
      src="/static/logo.png"
      {...props}
      width={width ? width : "56px"}
      height={height ? height : "56px"}
    />
  )
}

export default Logo
