import React, { useState } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { errorNotifier, successNotifier } from "../../Notifier"
import axios from "../../../axiosConfig"
import { checkPassStrength } from "../../utils"

const useStyles = makeStyles(() => ({
  root: {},
}))

const ProfileDetails = ({ user, className, setLoading }) => {
  const classes = useStyles()

  const { userID } = user
  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  })

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const changePassword = () => {
    if (userID === null || userID === undefined || userID === "") {
      errorNotifier("Cannot find logged user userID ")
      return
    }

    if (values.oldPassword === "") {
      errorNotifier("Please enter your old password")
      return
    }
    if (values.newPassword === "" || values.confirmNewPassword === "") {
      errorNotifier("Please enter your new password and confirm new password.")
      return
    }

    setLoading(true)
    axios
      .post(`users/passchange`, {
        userID: userID,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      })
      .then(({ data }) => {
        successNotifier("Password changed successfully")
        setLoading(false)
        setValues({
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        })
      })
      .catch((error) => {
        setLoading(false)
        errorNotifier(
          `Error happend while setting new password ...(${error.message})`
        )
      })
  }

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardHeader subheader="The password can be changed" title="Password" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Old Password"
                name="oldPassword"
                onChange={handleChange}
                required
                value={values.oldPassword || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="New Password"
                name="newPassword"
                onChange={handleChange}
                required
                value={values.newPassword || ""}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Confirm New Password"
                name="confirmNewPassword"
                onChange={handleChange}
                required
                value={values.confirmNewPassword || ""}
                variant="outlined"
              />
            </Grid>
            <div
              style={{ width: "96%", marginLeft: "auto", marginRight: "auto" }}
            >
              {values.newPassword && (
                <Typography
                  style={{
                    color:
                      checkPassStrength(values.newPassword) === "weak"
                        ? "red"
                        : checkPassStrength(values.newPassword) === "good"
                        ? "green"
                        : "darkgreen",
                  }}
                >
                  {"Your password strength is : "}
                  {checkPassStrength(values.newPassword)}
                </Typography>
              )}
              <Typography
                variant="body2"
                style={{ color: "grey", marginTop: "20px" }}
              >
                tips: for stronger password please use digits, lower, and upper
                case alphabets and non-word
              </Typography>
            </div>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={changePassword}>
            Change password
          </Button>
        </Box>
      </Card>
    </form>
  )
}

ProfileDetails.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
}

export default ProfileDetails
