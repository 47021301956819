import { title } from "../Styles/rootStyle"
import { primaryColor, accentColor } from "../Styles/rootStyle"
import tooltip from "../Styles/tooltipsStyle"

const footerStyle = (theme) => ({
  section: {
    padding: "70px 0",
    textAlign: "center",
    backgroundColor: accentColor,
  },
  title: {
    ...title,
    color: "white",
    textAlign: "left",
    paddingRight: "15%",
    paddingLeft: "15%",
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    width: "100%",
    marginLeft: "-15px",
  },
  description: {
    color: "#999",
    textAlign: "left",
    paddingRight: "15%",
    paddingLeft: "15%",
    width: "100%",
    marginLeft: "-15px",
    marginBottom: "10px",
  },
  line: {
    border: "0px",
    height: "1px",
    background: "#333",
    width: "50%",
    marginLeft: "12%",
    backgroundImage: `linear-gradient(to right, ${primaryColor}, ${accentColor}, ${primaryColor})`,
    marginBottom: "20px",
  },
  navLink: {
    color: "inherit",
    textDecoration: "none",
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginLeft: "10px",
    color: "white",
  },
  ...tooltip,
})

export default footerStyle
