import React, { useState, useEffect } from "react"
import { Container } from "@material-ui/core"
import UserPanel from "./../UserPanel"
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Box,
  Button,
} from "@material-ui/core"
import { withRouter } from "react-router-dom"
import axios from "../../../axiosConfig"
import { errorNotifier, successNotifier } from "../../Notifier"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Title from "../Title"
import MyDatePicker from "./../../MyDatePicker"
import { getLastWeekDate } from "./../../utils"
import IconButton from "@material-ui/core/IconButton"
import CardGiftcard from "@material-ui/icons/CardGiftcard"

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 355,
  },
}))

const SearchGlobalCards = ({ setLoading, history }) => {
  const classes = useStyles()

  const [logs, setLogs] = useState([])
  const [startdate, setStartdate] = useState(getLastWeekDate())
  const [enddate, setEnddate] = useState(new Date())

  const searchLog = () => {
    setLoading(true)
    axios
      .post(`Records/myglobal/date`, {
        startdate: startdate,
        enddate: enddate,
      })
      .then((res) => {
        if (res.data.length === 0) {
          successNotifier("No global cards found")
        }
        setLogs(res.data)
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, cannot get search result ...(${error.message})`
        )
        setLoading(false)
      })
  }

  useEffect(() => {
    searchLog()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UserPanel>
      <Container maxWidth={false}>
        <Card>
          <CardHeader
            title="Search for Global Cards"
            subheader="Getting list of global cards based on specificed range"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <MyDatePicker
                  style={{ width: "100%" }}
                  value={startdate}
                  fn={setStartdate}
                  label="Start Date"
                ></MyDatePicker>
              </Grid>
              <Grid item md={6} xs={12}>
                <MyDatePicker
                  style={{ width: "100%" }}
                  value={enddate}
                  fn={setEnddate}
                  label="End Date"
                ></MyDatePicker>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button color="primary" variant="contained" onClick={searchLog}>
              Search
            </Button>
          </Box>
        </Card>
        {logs.length > 0 && (
          <Grid container spacing={3} style={{ marginTop: "5px" }}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <>
                  <Grid item xs={12} container justify="space-evenly">
                    <Grid item xs={12}>
                      <Title>Global Cards</Title>
                    </Grid>
                  </Grid>

                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.rowHeader} align="left">
                          Date
                        </TableCell>
                        <TableCell className={classes.rowHeader} align="left">
                          Name
                        </TableCell>
                        <TableCell className={classes.rowHeader} align="left">
                          Type
                        </TableCell>
                        <TableCell className={classes.rowHeader} align="left">
                          Period
                        </TableCell>
                        <TableCell className={classes.rowHeader} align="left">
                          Code
                        </TableCell>
                        <TableCell className={classes.rowHeader} align="center">
                          Charged
                        </TableCell>
                        <TableCell className={classes.rowHeader} align="center">
                          Use
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {logs.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">
                            {new Date(`${row.date}Z`)
                              .toString()
                              .replace("GMT+0300 (Arabian Standard Time)", "")}
                          </TableCell>
                          <TableCell align="left">{row.global}</TableCell>
                          <TableCell align="left">{row.gtype}</TableCell>
                          <TableCell align="left">{row.period}</TableCell>
                          <TableCell align="left">{row.code}</TableCell>
                          <TableCell align="center">
                            {row.carged === true ? "Yes" : "NO"}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              disabled={row.total < 1}
                              color="primary"
                              aria-label="use"
                              component="span"
                              onClick={() => {
                                history.push({
                                  pathname: `/user_panel/mycards/global/use`,
                                  state: row,
                                })
                              }}
                            >
                              <CardGiftcard></CardGiftcard>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Container>
    </UserPanel>
  )
}

export default withRouter(SearchGlobalCards)
