import React from "react"
import { decode } from "jsonwebtoken"
import axios from "./../axiosConfig"

const createAxiosInstance = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
  }
}

const isAuthenticated = (user, logout) => {
  if (Object.keys(user).length === 0 && user.constructor === Object) {
    createAxiosInstance(null)
    return false
  }

  if (user && user["token"]) {
    const token = user["token"]

    var decodedToken = decode(token, { complete: true })
    const secondsSinceEpoch = Math.round(Date.now() / 1000)
    if (secondsSinceEpoch - decodedToken.payload.exp > 0) {
      createAxiosInstance(null)
      logout()
      return false
    } else {
      createAxiosInstance(token)
      return true
    }
  } else {
    createAxiosInstance(null)
    logout()
    return false
  }
}

const selectUniqueFileds = (data, fieldName) => {
  let uniqueFileds = []
  data.forEach((item) => {
    if (!uniqueFileds.includes(item[fieldName])) {
      uniqueFileds.push(item[fieldName])
    }
  })
  return uniqueFileds
}

const globalTypeFormatter = (data, argName, argTname, selectedType) => {
  if (data != null && Array.isArray(data) && data.length > 0) {
    let list = []
    let globalNames = selectUniqueFileds(data, argName)
    globalNames.forEach((name) => {
      list[name] = []
    })

    globalNames.forEach((name) => {
      let globalName = data.filter((item) => item[argName] === name)
      selectUniqueFileds(globalName, argTname).forEach((tname) => {
        list[name][tname] = []
      })
    })

    data.forEach((item) => {
      Object.keys(list).forEach((name) => {
        Object.keys(list[name]).forEach((tname) => {
          if (item[argName] === name && item[argTname] === tname) {
            list[name][tname].push({
              ...item,
            })
          }
        })
      })
    })

    if (selectedType === undefined) {
      return list
    } else {
      let selectedList = []
      Object.keys(list).forEach((key) => {
        if (key.toLocaleLowerCase() === selectedType) {
          selectedList = list[key]
        }
      })

      return selectedList
    }
  } else {
    return []
  }
}

const localTypeFormatter = (data, argName) => {
  if (data != null && Array.isArray(data) && data.length > 0) {
    let list = []
    let globalNames = selectUniqueFileds(data, argName)
    globalNames.forEach((name) => {
      list[name] = []
    })

    data.forEach((item) => {
      Object.keys(list).forEach((name) => {
        if (item[argName] === name) {
          list[name].push({
            ...item,
          })
        }
      })
    })

    return list
  } else {
    return []
  }
}

const typeFormatter = (data, argName, argTname, selectedType) => {
  if (argTname || selectedType) {
    const res = globalTypeFormatter(data, argName, argTname, selectedType)
    return res
  } else {
    const res = localTypeFormatter(data, argName)
    return res
  }
}

const fieldNameGenerator = (type, name, tname, period) => {
  let fieldName = `${type}_${name}_${tname}_${period}`
  // .toLocaleLowerCase()
  // .replace(/ /g, "")
  return fieldName
}

const scorePassword = (pass) => {
  let score = 0
  if (!pass) return score

  // award every unique letter until 5 repetitions
  let letters = {}
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1
    score += 5.0 / letters[pass[i]]
  }

  // bonus points for mixing it up
  var variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  }

  let variationCount = 0
  for (let check in variations) {
    variationCount += variations[check] === true ? 1 : 0
  }
  score += (variationCount - 1) * 10

  return parseInt(score)
}

const checkPassStrength = (pass) => {
  let score = scorePassword(pass)
  if (score > 80) return "strong"
  if (score > 60) return "good"
  if (score >= 30) return "weak"

  return "weak"
}

const getFormattedDate = (string) => {
  let dt
  if (string === undefined) {
    dt = new Date()
  } else {
    dt = new Date(string)
  }

  const year = dt.getFullYear()
  const month = (dt.getMonth() + 1).toString().padStart(2, "0")
  const day = dt.getDate().toString().padStart(2, "0")

  return year + "-" + month + "-" + day
}

const getNextWeekDate = () => {
  let firstDay = new Date()
  let nextWeek = new Date(firstDay.getTime() + 7 * 24 * 60 * 60 * 1000)
  return nextWeek
}

const getLastWeekDate = () => {
  let firstDay = new Date()
  let lastWeek = new Date(firstDay.getTime() - 7 * 24 * 60 * 60 * 1000)
  return lastWeek
}

const toFixed2 = (n) => {
  if ((Number(n) === n && n % 1 === 0) || (Number(n) === n && n % 1 !== 0)) {
    return n.toFixed(2)
  } else {
    return n
  }
}

const discountPrice = (loyalCustomer, price, discount) => {
  if (loyalCustomer && discount > 0) {
    return (
      <div>
        <span style={{ color: "green" }}>${toFixed2(discount)}</span>{" "}
        <span style={{ color: "red" }} className="strikethrough">
          ${toFixed2(price)}
        </span>
      </div>
    )
  } else {
    return `${toFixed2(price)} $`
  }
}

export {
  typeFormatter,
  fieldNameGenerator,
  isAuthenticated,
  scorePassword,
  checkPassStrength,
  getFormattedDate,
  getNextWeekDate,
  getLastWeekDate,
  toFixed2,
  discountPrice,
}
