import React from "react"
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { logoutUser } from "../actions/userActions"
import { isAuthenticated } from "./utils"

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, user, logout, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated(user, logout) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (user) => {
      dispatch(logoutUser())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
