import React from "react"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"

export default function Copyright({ color }) {
  return (
    <Typography
      variant="body2"
      align="center"
      style={{ color: color ? color : "white" }}
    >
      {"All Right Reserveed  "}
      {"  ©  " + new Date().getFullYear() + "   "}
      <Link color="inherit" href="http://receivers-system.com/">
        Receivers System
      </Link>{" "}
    </Typography>
  )
}
