import React from "react"
import { Container } from "@material-ui/core"
import UserPanel from "./../UserPanel"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@material-ui/core"
import CardGiftcard from "@material-ui/icons/CardGiftcard"
import { withRouter } from "react-router-dom"

const CardsSelector = ({ history }) => {
  return (
    <UserPanel>
      <Container maxWidth={false}>
        <Card>
          <CardHeader
            title="Select Hisotry"
            subheader="Select Local or Global Cards History"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Card>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    p={1}
                    m={1}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push("/user_panel/history/local")
                    }}
                  >
                    <Box p={1}>
                      <CardGiftcard style={{ fontSize: "25px" }}></CardGiftcard>
                    </Box>
                    <Box p={2} style={{ fontSize: "20px" }}>
                      My Local Cards History
                    </Box>
                  </Box>
                </Card>
              </Grid>
              <Grid item md={6} xs={12}>
                <Card>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    p={1}
                    m={1}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push("/user_panel/history/global")
                    }}
                  >
                    <Box p={1}>
                      <CardGiftcard style={{ fontSize: "25px" }}></CardGiftcard>
                    </Box>
                    <Box p={2} style={{ fontSize: "20px" }}>
                      My Global Cards History
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
      </Container>
    </UserPanel>
  )
}

export default withRouter(CardsSelector)
