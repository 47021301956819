import React from "react"
import { Container } from "@material-ui/core"
import UserPanel from "./../UserPanel"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@material-ui/core"
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasketOutlined"
import { withRouter } from "react-router-dom"

const PackagesSelector = ({ history }) => {
  return (
    <UserPanel>
      <Container maxWidth={false}>
        <Card>
          <CardHeader
            title="Buy Packages"
            subheader="Buy Local or Global Packages"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Card>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    p={1}
                    m={1}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push("buy_packages/local")
                    }}
                  >
                    <Box p={1}>
                      <ShoppingBasketIcon
                        style={{ fontSize: "25px" }}
                      ></ShoppingBasketIcon>
                    </Box>
                    <Box p={2} style={{ fontSize: "20px" }}>
                      Buy Local Packages
                    </Box>
                  </Box>
                </Card>
              </Grid>
              <Grid item md={6} xs={12}>
                <Card>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    p={1}
                    m={1}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push("buy_packages/global")
                    }}
                  >
                    <Box p={1}>
                      <ShoppingBasketIcon
                        style={{ fontSize: "25px" }}
                      ></ShoppingBasketIcon>
                    </Box>
                    <Box p={2} style={{ fontSize: "20px" }}>
                      Buy Global Packages
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
      </Container>
    </UserPanel>
  )
}

export default withRouter(PackagesSelector)
