import React, { useState, useEffect } from "react"
import { Container } from "@material-ui/core"
import UserPanel from "../UserPanel"
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import Title from "../Title"
import axios from "../../../axiosConfig"
import { errorNotifier } from "../../Notifier"
import IconButton from "@material-ui/core/IconButton"
import ShoppingCartOutlined from "@material-ui/icons/ShoppingCartOutlined"
import CloseOutlined from "@material-ui/icons/CloseOutlined"
import { withRouter } from "react-router-dom"
import { discountPrice } from "../../utils"
import { connect } from "react-redux"

const useStyles = makeStyles(() => ({
  rowHeader: {
    fontWeight: "bold",
  },
}))

const LocalViewer = ({ history, setLoading, user }) => {
  const classes = useStyles()
  const [localList, setLocalList] = useState([])

  const { userID } = user
  const [loyalCustomer, setLoyalCustomer] = useState(false)

  const getCurrentUser = (e) => {
    setLoading(true)
    axios
      .get(`users/${userID}`)
      .then(({ data }) => {
        setLoyalCustomer(data.discount)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        errorNotifier(
          `Error happend while get current user information ...(${error.message})`
        )
      })
  }

  useEffect(() => {
    setLoading(true)
    getCurrentUser()
    axios
      .get("codetypes/localview")
      .then((res) => {
        setLocalList(res.data)
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, cannot get local list ...(${error.message})`
        )
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <UserPanel>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <>
            <Grid item xs={12} container justify="space-evenly">
              <Grid item xs={12}>
                <Title>Local Packages Information</Title>
              </Grid>
            </Grid>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.rowHeader}>Name</TableCell>
                  <TableCell className={classes.rowHeader}>Period</TableCell>
                  <TableCell className={classes.rowHeader}>Price</TableCell>
                  <TableCell className={classes.rowHeader}>Total</TableCell>
                  <TableCell className={classes.rowHeader} align="center">
                    Buy
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.local}</TableCell>
                    <TableCell>{row.period}</TableCell>
                    <TableCell>
                      {discountPrice(loyalCustomer, row.price, row.discount)}
                    </TableCell>
                    <TableCell>{row.total}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        disabled={row.total < 1}
                        color="primary"
                        aria-label="buy card"
                        component="span"
                        onClick={() => {
                          history.push({
                            pathname: `/user_panel/buy_packages/local/buy`,
                            state: { ...row, loyalCustomer: loyalCustomer },
                          })
                        }}
                      >
                        {row.total < 1 ? (
                          <CloseOutlined></CloseOutlined>
                        ) : (
                          <ShoppingCartOutlined></ShoppingCartOutlined>
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        </Grid>
      </Container>
    </UserPanel>
  )
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default withRouter(connect(mapStateToProps)(LocalViewer))
