import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { makeStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import Header from "../../Header/Header"
import HeaderLinks from "../../HeaderLinks/HeaderLinks"
import PackagesViewerConent from "./PackagesViewerConent"
import Footer from "../../Footer/Footer"

import styles from "../packagesStyle"
import { accentColor } from "../../Styles/rootStyle"
import axios from "../../../axiosConfig"
import { typeFormatter } from "../../utils"
import { errorNotifier } from "../../Notifier"

const useStyles = makeStyles(styles)

function GlobalPackagesViewer(props) {
  const classes = useStyles()
  const [globalPackagesDetail, setGlobalPackagesDetail] = useState([])
  const { name, link, type, numberOfCat, ...rest } = props
  const { setLoading } = props

  const getGlobalPackagesDetail = () => {
    setLoading(true)
    axios
      .get(link)
      .then((res) => {
        if (type === "global") {
          setGlobalPackagesDetail(
            typeFormatter(res.data, "name", "tname", name.toLocaleLowerCase())
          )
        }
        if (type === "local") {
          setGlobalPackagesDetail(typeFormatter(res.data, "name"))
        }

        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, please try again...(${error.message})`
        )
        setLoading(false)
      })
  }

  useEffect(() => {
    getGlobalPackagesDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  return (
    <div>
      <Header
        color="transparent"
        brand="Receivers System"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div style={{ height: "200px", backgroundColor: accentColor }}></div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <PackagesViewerConent
            data={globalPackagesDetail ?? null}
            name={name}
            type={type}
            numberOfCat={numberOfCat}
          />
        </div>
        <Footer></Footer>
      </div>
    </div>
  )
}

export default withRouter(GlobalPackagesViewer)
