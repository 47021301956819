import React, { useState } from "react"
import UserPanel from "../UserPanel"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core"
import { withRouter } from "react-router-dom"
import { errorNotifier, successNotifier } from "../../Notifier"
import axios from "../../../axiosConfig"
import { connect } from "react-redux"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import Title from "../Title"

const useStyles = makeStyles(() => ({
  root: {},
}))

const intialValues = {
  url: "",
  code: "",
  sn: "",
}

const UseGlobalCard = ({ user, setLoading, admin, history }) => {
  const classes = useStyles()
  const userID = user?.userID

  const [values, setValues] = useState(intialValues)
  const [logs, setLogs] = useState([])

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const chargeCard = (submit) => {
    if (!userID) {
      errorNotifier("No user found, please try again")
      return
    }

    let data = {
      userID: Number(userID),
      url: values.url,
      code: values.code,
      sn: values.sn,
      submit: submit,
    }

    setLoading(true)
    axios
      .post(`purchase/testcard`, data)
      .then((res) => {
        let newLogs = [...logs]
        newLogs.unshift({
          code: values.code,
          sn: values.sn,
          submit: submit,
          result: res.data,
        })
        setLogs(newLogs)
        successNotifier(`successfully tested ${values.code} card`)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        errorNotifier(
          `Error happend while testing the card ...(${error.message} - ${error?.response?.data?.title})`
        )
      })
  }

  return (
    <UserPanel>
      <div className={classes.root}>
        <Card>
          <CardHeader
            title="Test Device"
            subheader="Testing card and device serial number"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Select Global Type"
                  name="url"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.url}
                  variant="outlined"
                >
                  {[
                    {
                      name: "GShare",
                      url: "http://www.renewbox.net/",
                    },
                    {
                      name: "Forever",
                      url: "http://tpoentrance.cc/",
                    },
                    {
                      name: "Funcam",
                      url: "http://funcam.rv.ua/",
                    },
                  ].map((option) => (
                    <option key={option.url} value={option.url}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Card"
                  name="code"
                  onChange={handleChange}
                  value={values.code}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Serial No"
                  name="sn"
                  onChange={handleChange}
                  required
                  value={values.sn}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                chargeCard("query")
              }}
              style={{ margin: "3px" }}
            >
              Query
            </Button>

            <Button
              color="primary"
              variant="contained"
              disabled={values.url === "http://funcam.rv.ua/"}
              onClick={() => {
                chargeCard("refresh sks")
              }}
              style={{ margin: "3px" }}
            >
              Refresh SKS
            </Button>
          </Box>
        </Card>
      </div>
      <Grid container spacing={1} style={{ marginTop: "10px" }}>
        <>
          <Grid item xs={12} container justify="space-evenly">
            <Grid item xs={12}>
              <Title>Device Logs</Title>
            </Grid>
          </Grid>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.rowHeader} align="left">
                  Code
                </TableCell>
                <TableCell className={classes.rowHeader} align="left">
                  SerialNo
                </TableCell>
                <TableCell className={classes.rowHeader} align="left">
                  Submit
                </TableCell>
                <TableCell className={classes.rowHeader} align="center">
                  Result
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{row.code}</TableCell>
                  <TableCell align="left">{row.sn}</TableCell>
                  <TableCell align="left">{row.submit}</TableCell>
                  <TableCell align="center">{row.result}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      </Grid>
    </UserPanel>
  )
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default withRouter(connect(mapStateToProps)(UseGlobalCard))
