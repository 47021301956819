import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Container, Grid } from "@material-ui/core"
import Profile from "./Profile"
import ProfileDetails from "./ProfileDetails"
import UserPanel from "../UserPanel"
import { errorNotifier } from "../../Notifier"
import axios from "../../../axiosConfig"

const Account = ({ user, setLoading }) => {
  const { userID } = user
  const [currentUser, setCurrentUser] = useState({})

  const getCurrentUser = (e) => {
    setLoading(true)
    axios
      .get(`users/${userID}`)
      .then(({ data }) => {
        setCurrentUser(data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        errorNotifier(
          `Error happend while get current user information ...(${error.message})`
        )
      })
  }

  useEffect(() => {
    getCurrentUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UserPanel>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Profile user={currentUser} />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <ProfileDetails user={currentUser} setLoading={setLoading} />
          </Grid>
        </Grid>
      </Container>
    </UserPanel>
  )
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps)(Account)
