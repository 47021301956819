import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 130,
    width: 130,
    marginTop: "25px",
    marginBottom: "25px",
  },
  notavailable: {
    color: "salmon",
  },
}))

const Profile = ({ user, className, ...rest }) => {
  const classes = useStyles()

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar className={classes.avatar} src={user.avatar} />
          <Typography color="textPrimary" gutterBottom variant="h5">
            {`${user.name}`}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            <b>User Name: </b>
            {user.userName || (
              <span className={classes.notavailable}>not available</span>
            )}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            <b>Email: </b>
            {user.email || (
              <span className={classes.notavailable}>not available</span>
            )}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            <b>Address: </b>{" "}
            {user.address || (
              <span className={classes.notavailable}>not available</span>
            )}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            <b>Phone: </b>{" "}
            {user.phone || (
              <span className={classes.notavailable}>not available</span>
            )}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

Profile.propTypes = {
  className: PropTypes.string,
}

export default Profile
