import React, { useState, useEffect } from "react"
import { Container } from "@material-ui/core"
import UserPanel from "../UserPanel"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Copyright from "./../../Footer/Copyright"
import Title from "../Title"
import { connect } from "react-redux"
import Profile from "../account/Profile"
import axios from "../../../axiosConfig"
import { errorNotifier } from "../../Notifier"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { toFixed2 } from "../../utils"

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 355,
  },
}))

const MyDashboard = ({ user }) => {
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  const [balance, setBalance] = useState(0)
  const [logs, setLogs] = useState([])

  const { userID } = user

  const getBalance = () => {
    axios
      .post(`users/balance`, {
        userID: userID,
      })
      .then((res) => {
        if (parseFloat(res.data).toFixed(2) >= 0) {
          setBalance(parseFloat(res.data).toFixed(2))
        }
      })
  }

  const getLogs = (code) => {
    axios
      .post(`Logs/userID/${userID}`)
      .then((res) => {
        setLogs(res.data.slice(0, 3))
      })
      .catch((error) => {
        errorNotifier(`Error happend while getting logs ...(${error.message})`)
      })
  }

  useEffect(() => {
    getBalance()
    getLogs()
    // eslint-disable-next-line
  }, [])

  return (
    <UserPanel>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <Paper>
              <Profile user={user} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className={fixedHeightPaper}>
              <div
                style={{
                  marginBottom: "10px",
                  textAlign: "center",
                  paddingTop: "50px",
                }}
              >
                <Title>My Current Balance</Title>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50%",
                }}
              >
                <Typography variant="h4">
                  {balance >= 0 ? `${toFixed2(balance)} $` : ""}
                </Typography>
              </div>
            </Paper>
          </Grid>

          <Grid container item xs={12} style={{ minHeight: "250px" }}>
            <Paper className={classes.paper}>
              {logs.length > 0 && (
                <>
                  <Grid item xs={12} container justify="space-evenly">
                    <Grid item xs={12}>
                      <Title>Card Logs</Title>
                    </Grid>
                  </Grid>

                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.rowHeader} align="left">
                          Date
                        </TableCell>
                        <TableCell className={classes.rowHeader} align="left">
                          SerialNo
                        </TableCell>
                        <TableCell className={classes.rowHeader} align="left">
                          Submit
                        </TableCell>
                        <TableCell className={classes.rowHeader} align="center">
                          Result
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {logs.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">
                            {new Date(`${row.date}Z`)
                              .toString()
                              .replace("GMT+0300 (Arabian Standard Time)", "")}
                          </TableCell>
                          <TableCell align="left">{row.sn}</TableCell>
                          <TableCell align="left">{row.submit}</TableCell>
                          <TableCell align="center">{row.result}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright color="black" />
        </Box>
      </Container>
    </UserPanel>
  )
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps)(MyDashboard)
