import React, { useState, Suspense } from "react"
import ThemeProvider from "./ThemeProvider" // use theme provider only for English
import { connect } from "react-redux"
import { loginUser, logoutUser } from "../actions/userActions"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom"
import { ToastContainer } from "react-toastify"
import ScrollToTop from "./ScrollToTop"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import PrivateRoute from "./PrivateRoute"
import SigninRoute from "./SigninRoute"

import LandingPage from "./LandingPage/LandingPage"
import PackagesViewer from "./Packages/PackagesViewer/PackagesViewer"
import Signin from "./Signin/Signin"

import UserPanelDashboard from "./UserPanel/dashboard/index"
import BuyPackages from "./UserPanel/packages/index"
import LocalPackages from "./UserPanel/LocalPackages/index"
import LocalPackagesBuy from "./UserPanel/LocalPackages/buy"
import GlobalPackages from "./UserPanel/GlobalPackages/index"
import GlobalPackagesBuy from "./UserPanel/GlobalPackages/buy"
import UserPanelAccount from "./UserPanel/account/index"

import MyCards from "./UserPanel/cards/index"
import LocalCards from "./UserPanel/LocalCards/index"
import GlobalCards from "./UserPanel/GlobalCards/index"
import UseGlobalCard from "./UserPanel/GlobalCards/use"
import TestGlobalCard from "./UserPanel/TestCards/test"

import Search from "./UserPanel/search/index"
import History from "./UserPanel/history/index"
import HistoryLocal from "./UserPanel/history/local"
import HistoryGlobal from "./UserPanel/history/global"

import Transaction from "./UserPanel/Transaction/index"

import NotFoundContent from "./NotFoundContent"
import orange from "@material-ui/core/colors/orange"

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: orange[500],
  },
}))

function App() {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)

  // const { classes, user } = this.props;

  return (
    <div>
      <Suspense
        fallback={
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100vh" }}
          >
            <CircularProgress color="inherit" />
          </Box>
        }
      >
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <ThemeProvider>
          <Router>
            <ScrollToTop>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => {
                    return <LandingPage setLoading={setLoading}></LandingPage>
                  }}
                />

                <Route exact path="/global/gshare">
                  <PackagesViewer
                    setLoading={setLoading}
                    name="GShare"
                    type="global"
                    link="codetypes/GlobalDetail"
                    numberOfCat="three"
                  ></PackagesViewer>
                </Route>

                <Route exact path="/global/forever">
                  <PackagesViewer
                    setLoading={setLoading}
                    name="Forever"
                    type="global"
                    link="codetypes/GlobalDetail"
                    numberOfCat="three"
                  ></PackagesViewer>
                </Route>

                <Route exact path="/global/funcam">
                  <PackagesViewer
                    setLoading={setLoading}
                    name="Funcam"
                    type="global"
                    link="codetypes/GlobalDetail"
                    numberOfCat="two"
                  ></PackagesViewer>
                </Route>

                <Route exact path="/local">
                  <PackagesViewer
                    setLoading={setLoading}
                    name="Extra"
                    type="local"
                    link="codetypes/LocalDetail"
                    numberOfCat="four"
                  ></PackagesViewer>
                </Route>

                <SigninRoute exact path="/Signin">
                  <Signin setLoading={setLoading}></Signin>
                </SigninRoute>

                <PrivateRoute exact path="/user_panel">
                  <UserPanelDashboard />
                </PrivateRoute>

                <PrivateRoute exact path="/user_panel/account">
                  <UserPanelAccount setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/user_panel/buy_packages">
                  <BuyPackages setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/user_panel/buy_packages/local">
                  <LocalPackages setLoading={setLoading} />
                </PrivateRoute>
                <PrivateRoute exact path="/user_panel/buy_packages/local/buy">
                  <LocalPackagesBuy setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/user_panel/buy_packages/global">
                  <GlobalPackages setLoading={setLoading} />
                </PrivateRoute>
                <PrivateRoute exact path="/user_panel/buy_packages/global/buy">
                  <GlobalPackagesBuy setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/user_panel/mycards">
                  <MyCards setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/user_panel/mycards/local">
                  <LocalCards setLoading={setLoading} />
                </PrivateRoute>
                <PrivateRoute exact path="/user_panel/mycards/global">
                  <GlobalCards setLoading={setLoading} />
                </PrivateRoute>
                <PrivateRoute exact path="/user_panel/mycards/global/use">
                  <UseGlobalCard setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/user_panel/global/test_card">
                  <TestGlobalCard setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/user_panel/search">
                  <Search setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/user_panel/history">
                  <History setLoading={setLoading} />
                </PrivateRoute>
                <PrivateRoute exact path="/user_panel/history/local">
                  <HistoryLocal setLoading={setLoading} />
                </PrivateRoute>
                <PrivateRoute exact path="/user_panel/history/global">
                  <HistoryGlobal setLoading={setLoading} />
                </PrivateRoute>

                <PrivateRoute exact path="/user_panel/transactions">
                  <Transaction setLoading={setLoading} />
                </PrivateRoute>

                <Route path="*" component={NotFoundContent}></Route>
              </Switch>
            </ScrollToTop>
          </Router>
          <ToastContainer />
        </ThemeProvider>
      </Suspense>
    </div>
  )
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => {
      dispatch(loginUser(user))
    },
    logout: (user) => {
      dispatch(logoutUser())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
