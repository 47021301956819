import React from "react"
import clsx from "clsx"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import DashboardIcon from "@material-ui/icons/DashboardOutlined"
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasketOutlined"
import CardGiftcard from "@material-ui/icons/CardGiftcard"
import UserIcon from "@material-ui/icons/AccountCircleOutlined"
import SearchOutlined from "@material-ui/icons/SearchOutlined"
import QuestionAnswerOutlined from "@material-ui/icons/QuestionAnswerOutlined"
import HistoryOutlined from "@material-ui/icons/HistoryOutlined"
import TransformOutlined from "@material-ui/icons/TransformOutlined"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import Logo from "../../Logo"
import styles from "../UserPanelStyle"

const useStyles = makeStyles(styles)

function MyDrawer(props) {
  const { open, handleDrawerClose, history } = props
  const classes = useStyles()

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
        >
          <Logo width="46px" height="46px"></Logo>
        </Box>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            history.push("/user_panel")
          }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/user_panel/buy_packages")
          }}
        >
          <ListItemIcon>
            <ShoppingBasketIcon />
          </ListItemIcon>
          <ListItemText primary="Buy Packages" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/user_panel/mycards")
          }}
        >
          <ListItemIcon>
            <CardGiftcard />
          </ListItemIcon>
          <ListItemText primary="My Cards" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/user_panel/search")
          }}
        >
          <ListItemIcon>
            <SearchOutlined />
          </ListItemIcon>
          <ListItemText primary="Log Search" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/user_panel/history")
          }}
        >
          <ListItemIcon>
            <HistoryOutlined />
          </ListItemIcon>
          <ListItemText primary="Card History" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/user_panel/global/test_card")
          }}
        >
          <ListItemIcon>
            <QuestionAnswerOutlined />
          </ListItemIcon>
          <ListItemText primary="Test Device" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/user_panel/transactions")
          }}
        >
          <ListItemIcon>
            <TransformOutlined />
          </ListItemIcon>
          <ListItemText primary="Transactions" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/user_panel/account")
          }}
        >
          <ListItemIcon>
            <UserIcon />
          </ListItemIcon>
          <ListItemText primary="Account" />
        </ListItem>
      </List>
    </Drawer>
  )
}

export default withRouter(MyDrawer)
