import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import "react-toastify/dist/ReactToastify.css"
import App from "./components/App"
import * as serviceWorker from "./serviceWorker"

import { createStore, combineReducers, applyMiddleware } from "redux"
import { Provider } from "react-redux"
import reducers from "./reducers/index"
import { createLogger } from "redux-logger" // Redux logging tool

import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import { PersistGate } from "redux-persist/integration/react"

const logger = createLogger() // create logger from redux-logger
const reducer = combineReducers(reducers)
const persistConfig = {
  key: "root",
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)

let store

// react out of the box support envirtoment
// https://create-react-app.dev/docs/adding-custom-environment-variables/

if (process.env.NODE_ENV === "production") {
  store = createStore(persistedReducer)
} else {
  store = createStore(persistedReducer, applyMiddleware(logger))
}

let persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
