import React, { useEffect, useState } from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import MenuIcon from "@material-ui/icons/Menu"
import HomeIcon from "@material-ui/icons/HomeOutlined"
import ExitToAppIcon from "@material-ui/icons/ExitToAppOutlined"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { Box } from "@material-ui/core"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { logoutUser } from "../../../actions/userActions"
import axios from "../../../axiosConfig"
import { successNotifier } from "../../Notifier"
import styles from "../UserPanelStyle"
import { toFixed2 } from "../../utils"

const useStyles = makeStyles(styles)

function MyAppBar(props) {
  const { open, handleDrawerOpen } = props
  const { history, user, logout } = props
  const [balance, setBalance] = useState(0)

  const getBalance = () => {
    const { userID } = user
    axios
      .post(`users/balance`, {
        userID: userID,
      })
      .then((res) => {
        if (parseFloat(res.data).toFixed(2) >= 0) {
          setBalance(parseFloat(res.data).toFixed(2))
        }
      })
  }

  const exitToApp = () => {
    const { token } = user

    axios.post(`Logout`, {
      token: token,
    })
    successNotifier("User logged out successfully")
    logout()
  }

  useEffect(() => {
    getBalance()
    // eslint-disable-next-line
  }, [])

  const classes = useStyles()
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: open,
          })}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          Receivers System - User Panel
        </Typography>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex"
          flexGrow={1}
        >
          <IconButton color="inherit">
            <Typography variant="body1">{`${toFixed2(balance)} $`}</Typography>
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() => {
              history.push("/")
            }}
          >
            <HomeIcon />
          </IconButton>
          <IconButton color="inherit" onClick={exitToApp}>
            <ExitToAppIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (user) => {
      dispatch(logoutUser())
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyAppBar)
)
