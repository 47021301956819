import React, { useEffect, useState } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import GridContainer from "../Custom/GridContainer/GridContainer"
import GridItem from "../Custom/GridItem/GridItem"
import Button from "../Custom/Button/Button"
import { withRouter } from "react-router-dom"

import styles from "./packagesStyle"
import { Paper, Typography } from "@material-ui/core"

import axios from "../../axiosConfig"
import { typeFormatter } from "../utils"
import { errorNotifier } from "../Notifier"
import Skeleton from "@material-ui/lab/Skeleton"

const useStyles = makeStyles(styles)

function GlobalPackages(props) {
  const classes = useStyles()
  const [globalPackagesDetail, setGlobalPackagesDetail] = useState([])
  const { history } = props
  const { setLoading } = props

  const getGlobalPackagesDetail = () => {
    setLoading(true)
    axios
      .get(`codetypes/GlobalDetail`)
      .then((res) => {
        setGlobalPackagesDetail(typeFormatter(res.data, "name", "tname"))
        setLoading(false)
      })
      .catch((error) => {
        errorNotifier(
          `Somethign went wrong, please try again...(${error.message})`
        )
        setLoading(false)
      })
  }

  useEffect(() => {
    getGlobalPackagesDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <Typography variant="h4" className={classes.title}>
            Global Packages
          </Typography>
          <Typography variant="body1" className={classes.description}>
            Our global packages consist of three main package.
          </Typography>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          {Object.keys(globalPackagesDetail).length > 0 &&
            Object.keys(globalPackagesDetail).map((name, index) => (
              <GridItem xs={12} sm={12} md={4} key={index}>
                <Paper
                  elevation={4}
                  style={{ height: "320px", position: "relative" }}
                >
                  <div
                    className={
                      index % 2 === 0
                        ? classes.packageHeaderAccent
                        : classes.packageHeaderPrimary
                    }
                  >
                    <Typography variant="h5">{name}</Typography>
                  </div>
                  {Object.keys(globalPackagesDetail[name]).map(
                    (tname, index) => (
                      <div className={classes.packageContent} key={index}>
                        <Typography variant="h6">{tname}</Typography>
                      </div>
                    )
                  )}
                  <div
                    className={classes.buttonContent}
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: "50%",
                      WebkitTransform: "translateX(-50%)",
                      transform: "translateX(-50%)",
                    }}
                  >
                    <Button
                      color={index % 2 === 0 ? "accent" : "primary"}
                      size="sm"
                      rel="noopener noreferrer"
                      onClick={() => {
                        history.push(`/global/${name.toLocaleLowerCase()}`)
                      }}
                    >
                      More {name} Info
                    </Button>
                  </div>
                </Paper>
              </GridItem>
            ))}
          {Object.keys(globalPackagesDetail).length === 0 &&
            [1, 2, 3].map((name, index) => (
              <GridItem xs={12} sm={12} md={4} key={index}>
                <Paper elevation={1} style={{ height: "320px" }}>
                  <Skeleton variant="rect" width="100%" height="100%" />
                </Paper>
              </GridItem>
            ))}
        </GridContainer>
      </div>
    </div>
  )
}

export default withRouter(GlobalPackages)
