import React from "react"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import purple from "@material-ui/core/colors/purple"
import blueGrey from "@material-ui/core/colors/blueGrey"

const theme = createMuiTheme({
  palette: {
    primary: blueGrey,
    secondary: purple,
  },
})

export default function Direction(props) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  )
}
