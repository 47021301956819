import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import GridContainer from "../../Custom/GridContainer/GridContainer"
import GridItem from "../../Custom/GridItem/GridItem"
import styles from "../packagesStyle"
import { Paper, Typography } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { toFixed2 } from "../../utils"

const useStyles = makeStyles(styles)

function GlobalPackagesViewerConent(props) {
  const classes = useStyles()

  const { name, numberOfCat, type, data } = props

  const mdDivider = type === "local" ? 24 : 12

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <Typography variant="h4" className={classes.title}>
            {name} Packages
          </Typography>
          <Typography variant="body1" className={classes.description}>
            Our {name} packages consist of {numberOfCat} main categories.
          </Typography>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          {data !== null &&
            Object.keys(data).length > 0 &&
            Object.keys(data).map((name, index) => (
              <GridItem
                xs={12}
                sm={12}
                md={Math.ceil(mdDivider / Object.keys(data).length)}
                key={index}
              >
                <Paper elevation={4}>
                  <div
                    className={
                      Object.keys(data).length === 4
                        ? index === 0 || index === Object.keys(data).length - 1
                          ? classes.packageHeaderAccent
                          : classes.packageHeaderPrimary
                        : Object.keys(data).length !== 4 && index % 2 === 0
                        ? classes.packageHeaderAccent
                        : classes.packageHeaderPrimary
                    }
                  >
                    <Typography variant="h5">{name}</Typography>
                  </div>
                  {Object.keys(data[name]).map((tname, index) => (
                    <div
                      className={classes.packageContent}
                      key={index}
                      style={{ display: "flex" }}
                    >
                      <div style={{ flex: 4 }}>
                        {" "}
                        <Typography variant="h6">
                          {data[name][tname]["period"]}
                          {"  -  "}
                          {toFixed2(data[name][tname]["price"])}
                          {" $"}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </Paper>
              </GridItem>
            ))}
          {data === null ||
            (Object.keys(data).length === 0 &&
              [1, 2, 3].map((name, index) => (
                <GridItem xs={12} sm={12} md={4} key={index}>
                  <Paper elevation={1} style={{ height: "320px" }}>
                    <Skeleton variant="rect" width="100%" height="100%" />
                  </Paper>
                </GridItem>
              )))}
        </GridContainer>
      </div>
    </div>
  )
}

export default GlobalPackagesViewerConent
