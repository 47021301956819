import React, { useState, useEffect } from "react"
import UserPanel from "../UserPanel"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core"
import { withRouter } from "react-router-dom"
import { errorNotifier, successNotifier } from "../../Notifier"
import axios from "../../../axiosConfig"
import { connect } from "react-redux"
import { toFixed2 } from "../../utils"

const useStyles = makeStyles(() => ({
  root: {},
}))

const intialValues = {
  detailID: null,
  local: "",
  period: "",
  periodID: "",
  price: 0,
  total: 0,
  quantity: 0,
  discount: 0,
  loyalCustomer: false,
}

const BuyLocalCard = ({ user, setLoading, admin, history }) => {
  const classes = useStyles()
  const userID = user?.userID

  const [values, setValues] = useState(intialValues)
  const [error, setError] = useState(false)

  const handleChange = (event) => {
    if (Number(event.target.value) <= values.total) {
      setError(false)
    } else {
      setError(true)
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const buyCard = () => {
    if (!userID) {
      errorNotifier("No user found, please try again")
      return
    }
    if (values.detailID === null || values.periodID === "") {
      errorNotifier("No card info found, please try again.")
      return
    }
    if (isNaN(values.quantity) === true || Number(values.quantity) <= 0) {
      errorNotifier("Input correct amount of cards to buy")
      return
    }

    let data = {
      userID: userID,
      detailID: values.detailID,
      date: new Date().toJSON(),
      preiodID: values.periodID,
      quantity: Number(values.quantity),
    }

    axios
      .post(`records/purchase`, data)
      .then((res) => {
        setLoading(false)
        if (res.status === 200 && res.data?.type === "success") {
          successNotifier(`successfully bought ${values.quantity} cards`)
          history.goBack()
        } else if (
          res.status === 200 &&
          res.data?.type === "outofavailability"
        ) {
          if (Number(res.data?.message) > 0) {
            setValues({
              ...values,
              quantity: Number(res.data?.message),
            })
          }
          errorNotifier(`Only ${res.data?.message} are available to buy`)
        } else if (res.status === 200 && res.data?.type === "outofbalance") {
          errorNotifier(`${res.data?.type} : ${res.data?.message}`)
        } else if (res.status === 200 && res.data?.type === "unkown") {
          errorNotifier(`${res.data?.message} : unknown error happen`)
        }
      })
      .catch((error) => {
        setLoading(false)
        errorNotifier(
          `Somethign went wrong please try again. ...(${error?.message})`
        )
      })
  }

  useEffect(() => {
    let state = history.location.state

    if (state) {
      setValues({
        ...values,
        ...state,
      })
    } else {
      errorNotifier("Somethign went wrong please try again.")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UserPanel>
      <div className={classes.root}>
        <Card>
          <CardHeader
            title="Buy Local Card"
            subheader="Buying cards for local packages"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Local Type"
                  name="local"
                  disabled
                  value={values.local}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Period Type"
                  name="period"
                  disabled
                  value={values.period}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Price"
                  name="price"
                  disabled
                  value={
                    values.loyalCustomer && values.discount > 0
                      ? toFixed2(values.discount)
                      : toFixed2(values.price)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Total"
                  name="total"
                  disabled
                  value={values.total}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  helperText={
                    error
                      ? `Max allowed quantity to buy is ${values.total}`
                      : "Please specify quantity to buy"
                  }
                  label="quantity"
                  name="quantity"
                  onChange={handleChange}
                  required
                  type="number"
                  value={values.quantity}
                  variant="outlined"
                  inputProps={{ min: "0", step: "1" }}
                  error={error}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button color="primary" variant="contained" onClick={buyCard}>
              Buy Card
            </Button>
          </Box>
        </Card>
      </div>
    </UserPanel>
  )
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default withRouter(connect(mapStateToProps)(BuyLocalCard))
