import React, { useState, useEffect } from "react"
import UserPanel from "../UserPanel"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core"
import { withRouter } from "react-router-dom"
import { errorNotifier, successNotifier } from "../../Notifier"
import axios from "../../../axiosConfig"
import { connect } from "react-redux"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import Title from "../Title"

const useStyles = makeStyles(() => ({
  root: {},
}))

const intialValues = {
  detailID: "",
  code: "",
  global: "",
  gtype: "",
  period: "",
  serialNo: "",
}

const UseGlobalCard = ({ user, setLoading, admin, history }) => {
  const classes = useStyles()
  const userID = user?.userID

  const [values, setValues] = useState(intialValues)
  const [error, setError] = useState(false)
  const [logs, setLogs] = useState([])

  const handleChange = (event) => {
    if (
      event.target.value.length === 12 &&
      event.target.value !== "130101000002" &&
      /^\d+$/.test(event.target.value)
    ) {
      setError(false)
    } else {
      setError(true)
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const chargeCard = (submit) => {
    if (!userID) {
      errorNotifier("No user found, please try again")
      return
    }

    if (values.detailID === "") {
      errorNotifier("Card detail is missing, please try again")
      return
    }

    let data = {
      userID: Number(userID),
      detailID: Number(values.detailID),
      code: values.code,
      sn: values.serialNo,
      submit: submit,
    }

    setLoading(true)
    axios
      .post(`purchase`, data)
      .then((res) => {
        successNotifier(`successfully used ${values.code} card`)
        getLogs(values.code)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        errorNotifier(
          `Error happend while using the card ...(${error.message})`
        )
      })
  }

  const getLogs = (code) => {
    axios
      .post(`Logs/code/${code}`)
      .then((res) => {
        setLogs(res.data)
      })
      .catch((error) => {
        errorNotifier(`Error happend while getting logs ...(${error.message})`)
      })
  }

  useEffect(() => {
    let state = history.location.state

    if (state) {
      setValues({
        ...values,
        ...state,
      })
    } else {
      errorNotifier("Somethign went wrong please try again.")
    }
    if (state.code) {
      getLogs(state.code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UserPanel>
      <div className={classes.root}>
        <Card>
          <CardHeader
            title="Use Global Card"
            subheader="Using card for global packages"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Global Name"
                  name="global"
                  disabled
                  value={values.global}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Global Type"
                  name="gtype"
                  disabled
                  value={values.gtype}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Period Type"
                  name="period"
                  disabled
                  value={values.period}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Card"
                  name="code"
                  disabled
                  value={values.code}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText={
                    error
                      ? "Serial number must be 12 digit number, must not be equal too 130101000002"
                      : error === false && values.serialNo.length === 12
                      ? ""
                      : "Please specify device serial number"
                  }
                  label="Serial No"
                  name="serialNo"
                  onChange={handleChange}
                  required
                  value={values.serialNo}
                  variant="outlined"
                  error={error}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            {/* <Button
              color="primary"
              variant="contained"
              onClick={() => {
                chargeCard("query")
              }}
              style={{ margin: "3px" }}
              disabled={values.code.length < 13 || values.code.length > 15}
            >
              Query
            </Button> */}
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                chargeCard("charge")
              }}
              disabled={
                values.serialNo.length !== 12 ||
                values.serialNo.length !== 12 ||
                error === true
              }
              style={{ margin: "3px" }}
            >
              Charge
            </Button>
            {/* {values.global.toLocaleLowerCase() !== "funcam" && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  chargeCard("refresh sks")
                }}
                disabled={values.code.length < 13 || values.code.length > 15}
                style={{ margin: "3px" }}
              >
                Refresh SKS
              </Button>
            )} */}
          </Box>
        </Card>
      </div>
      <Grid container spacing={1} style={{ marginTop: "10px" }}>
        <>
          <Grid item xs={12} container justify="space-evenly">
            <Grid item xs={12}>
              <Title>Card Log</Title>
            </Grid>
          </Grid>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.rowHeader} align="left">
                  Date
                </TableCell>
                <TableCell className={classes.rowHeader} align="left">
                  SerialNo
                </TableCell>
                <TableCell className={classes.rowHeader} align="left">
                  Submit
                </TableCell>
                <TableCell className={classes.rowHeader} align="center">
                  Result
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="left">
                    {new Date(`${row.date}Z`)
                      .toString()
                      .replace("GMT+0300 (Arabian Standard Time)", "")}
                  </TableCell>
                  <TableCell align="left">{row.sn}</TableCell>
                  <TableCell align="left">{row.submit}</TableCell>
                  <TableCell align="center">{row.result}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      </Grid>
    </UserPanel>
  )
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default withRouter(connect(mapStateToProps)(UseGlobalCard))
